import {apiCall} from '../ApiCall';
import {Dispatch} from '@reduxjs/toolkit';
import { FinanceData } from './UserActions';

type GetCurrenciesResponse = {
  currencies: CurrencyData[];
};

export type CurrencyData = {
  id: number;
  symbol: number;
};

type getCurrenciesAction = {
  readonly type: 'GET_CURRENCIES_ACTION';
  readonly payload: CurrencyData[];
};

export const getCurrencies = () => async (
  dispatch: Dispatch<getCurrenciesAction>,
) => {
  apiCall
    .makeJsonApiCall<GetCurrenciesResponse>('GET', '/currencies/all')
    .then((response) => {
      console.log(response);

      dispatch({
        type: 'GET_CURRENCIES_ACTION',
        payload: response.currencies,
      });
    }).catch(console.error);
};

type createCurrencyAction = {
  readonly type: 'CREATE_CURRENCY_ACTION';
};

export const createCurrency = () => async (
  dispatch: Dispatch<createCurrencyAction>,
) => {
  dispatch({
    type: 'CREATE_CURRENCY_ACTION',
  });
};

type addPaymentAction = {
  readonly type: 'ADD_PAYMENT_ACTION';
};

export const addPayment = (data: {
  paymentDate: string, amount_usdt: number, amount_stb: number, stb_price: number, current_stb_price: number, user_id: number, notes: string
}, cb: (err?: string) => void) => async (
  _: Dispatch<addPaymentAction>,
) => {
  apiCall.makeJsonApiCall<FinanceData[]>('POST', '/finances/payment', data)
  .then(_ => {
    cb()
  }).catch(err => {
    console.log(err);
    if(typeof err === "string"){
      cb(err);
    } else {
      cb(err.message);
    }
  });
};

type editPaymentAction = {
  readonly type: 'EDIT_PAYMENT_ACTION';
};

export const editPaymentApi = (data: any, cb: (error?: string) => void) => async (
  _: Dispatch<editPaymentAction>,
) => {
  apiCall.makeJsonApiCall<FinanceData[]>('PUT', '/finances/payment', data)
  .then(_ => {
    cb();
  }).catch((err) => {
    console.log(err);
    if(typeof err === "string"){
      cb(err);
    } else {
      cb(err.message);
    }
  });
};

export type Actions = getCurrenciesAction | createCurrencyAction | addPaymentAction | editPaymentAction;
