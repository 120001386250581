// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
  display: flex;
  color: white;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.link:hover {
  background: lightslategray;
  color: black;
  transition: all 0.5s;
}

.active {
  background: lightslategray;
  color: black;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/styled/styledSidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,0BAA0B;EAC1B,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".link {\n  display: flex;\n  color: white;\n  padding: 10px 15px;\n  gap: 15px;\n  transition: all 0.5s;\n  text-decoration: none;\n}\n\n.link:hover {\n  background: lightslategray;\n  color: black;\n  transition: all 0.5s;\n}\n\n.active {\n  background: lightslategray;\n  color: black;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
