import {apiCall} from '../ApiCall';
import {Dispatch} from '@reduxjs/toolkit';

type LoginRequest = {
  login: string;
  password: string;
};

export type FinanceData = {
  id: number;
  payment_date: string;
  amount_usdt: number;
  amount_stb: number;
  stb_price: number;
  current_stb_price: number;
  user_id: number;
  payout_date: string;
  type: string;
  notes: string;
};

type UsersResponse = {
  users: Array<UserData>;
};

export type UserData = {
  id: number;
  login: string;
  role: string;
  name: string;
  is_admin: boolean;
  finances: FinanceData[];
};

export type LoginResponse = {
  accessToken: string;
  profile: UserData;
};

type loginAction = {
  readonly type: 'LOGIN_ACTION';
  readonly payload: LoginResponse;
};

export const loginUser =
  (loginReuqest: LoginRequest) => async (dispatch: Dispatch<loginAction>) => {
    apiCall
      .makeJsonApiCall<LoginResponse>('POST', '/users/login', loginReuqest)
      .then((response) => {
        dispatch({
          type: 'LOGIN_ACTION',
          payload: response,
        });
      }).catch(console.error);
  };

type logoutAction = {
  readonly type: 'LOGOUT_ACTION';
};

export const logout = () => async (dispatch: Dispatch<logoutAction>) => {
  dispatch({
    type: 'LOGOUT_ACTION',
  });
};

type usersAction = {
  readonly type: 'USERS_ACTION';
  readonly payload: Array<UserData>;
};

export const getUsers = () => async (dispatch: Dispatch<usersAction>) => {
  apiCall.makeJsonApiCall<UsersResponse>('GET', '/users').then((response) => {
    dispatch({
      type: 'USERS_ACTION',
      payload: response.users,
    });
  }).catch(console.error);
};

type addUserAction = {
  readonly type: 'USER_ADD_ACTION';
  readonly payload: UserData;
};

export type AddUserReponse = {
  user: UserData;
};

export const addNewUser =
  (data: {login: string; name: string; password: string}) =>
  async (dispatch: Dispatch<addUserAction>) => {
    apiCall
      .makeJsonApiCall<AddUserReponse>('POST', '/users/add', data)
      .then((response) => {
        dispatch({
          type: 'USER_ADD_ACTION',
          payload: response.user,
        });
      }).catch(console.error);
  };

type getUserHistoryAction = {
  readonly type: 'USER_HISTORY';
  readonly payload: FinanceData[];
};

export type GetUserHistoryResponse = {
  finances: FinanceData[];
};

export const getUserHistory =
  () => async (dispatch: Dispatch<getUserHistoryAction>) => {
    apiCall
      .makeJsonApiCall<GetUserHistoryResponse>('GET', '/finances/history')
      .then((response) => {
        dispatch({
          type: 'USER_HISTORY',
          payload: response.finances,
        });
      }).catch(console.error);
  };

type disableUserAction = {
  readonly type: 'DISABLE_USER';
};

export const disableUser =
  (userId: number, cb: () => void) =>
  async (
    dispatch: Dispatch<
      | disableUserAction
      | successMessageAction
      | errorMessageAction
      | clearMessagesAction
    >,
  ) => {
    apiCall
      .makeJsonApiCall<Boolean>('GET', `/users/disable/${userId}`)
      .then((_) => {
        dispatch({
          type: 'DISABLE_USER',
        });
        postMessage(dispatch, 'User disabled');
        cb();
      })
      .catch((err) => {
        console.log(err);
        if (typeof err === 'string') {
          postMessage(dispatch, undefined, err);
        } else {
          postMessage(dispatch, undefined, err.message);
        }
      });
  };

type editUserAction = {
  readonly type: 'EDIT_USER';
};

export const editUser =
  (
    data: {id: number; name?: string; login?: string; password?: string},
    cb: () => void,
  ) =>
  async (
    dispatch: Dispatch<
      | editUserAction
      | successMessageAction
      | errorMessageAction
      | clearMessagesAction
    >,
  ) => {
    apiCall
      .makeJsonApiCall<Boolean>('POST', `/users/edit`, data)
      .then(() => {
        dispatch({
          type: 'EDIT_USER',
        });
        postMessage(dispatch, 'Changes saved.');
        cb();
      })
      .catch((err) => {
        if (typeof err === 'string') {
          postMessage(dispatch, undefined, err);
        } else {
          postMessage(dispatch, undefined, err.message);
        }
      });
  };

type errorMessageAction = {
  readonly type: 'ERROR_MESSAGE';
  readonly payload: string;
};

type successMessageAction = {
  readonly type: 'SUCCESS_MESSAGE';
  readonly payload: string;
};

type clearMessagesAction = {
  readonly type: 'CLEAR_MESSAGES';
};

export const clearMessages =
  () => async (dispatch: Dispatch<clearMessagesAction>) => {
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
  };

function postMessage(
  dispatch: Dispatch<
    errorMessageAction | successMessageAction | clearMessagesAction
  >,
  success?: string,
  error?: string,
) {
  if (success) {
    dispatch({
      type: 'SUCCESS_MESSAGE',
      payload: success,
    });
  }
  if (error) {
    dispatch({
      type: 'ERROR_MESSAGE',
      payload: error,
    });
  }
  setTimeout(() => {
    dispatch({
      type: 'CLEAR_MESSAGES',
    });
  }, 2000);
}

export type Actions =
  | loginAction
  | logoutAction
  | usersAction
  | addUserAction
  | getUserHistoryAction
  | disableUserAction
  | errorMessageAction
  | successMessageAction
  | clearMessagesAction;
