import { Button, createStyles, makeStyles, TextField, Theme, LinearProgress } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { FinanceData } from "../../api/actions/UserActions";

export default function EditPayment(props: {
    open: boolean, 
    userId: number,
    handleClose: () => void, 
    editPayment: (payment_date: string | undefined, amount_usdt: number | undefined, amount_stb: number | undefined, stb_price: number| undefined, 
      current_stb_price: number | undefined, finance_id: number, notes: string | undefined) => void,
    data: FinanceData,
    error: string | undefined,
    success: string | undefined,
    loading: boolean,
  }){
    

  const classes = useStyles();
  const [paymentDate, setPaymentDate] = useState<string | undefined>();
  const [amountUsdt, setAmountUsdt] = useState<number | undefined>();
  const [amountStb, setAmountStb] = useState<number | undefined>();
  const [stbPrice, setStbPrice] = useState<number | undefined>();
  const [currentStbPrice, setCurrentStbPrice] = useState<number | undefined>();
  const [notes, setNotes] = useState<string | undefined>();
  
  return (
    <div>
      <Modal
        className={classes.modalStyle}
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <form className={classes.formStyle} >
            <TextField
              id="paymentDate"
              label="Payment Date"
              type="date"
              defaultValue={props.data.payment_date}
              onChange={(e) => setPaymentDate(e.target.value)}
            />
            <TextField
              id="amountUsdt"
              label="Amount USDT"
              type="number"
              defaultValue={Number(props.data.amount_usdt).toFixed()}
              onChange={(e) => setAmountUsdt(Number(e.target.value))}
            />
            <TextField
              id="amountStb"
              label="Amount STB"
              type="number"
              defaultValue={Number(props.data.amount_stb).toFixed()}
              onChange={(e) => setAmountStb(Number(e.target.value))}
            />
            <TextField
              id="stbPrice"
              label="STB Price"
              type="number"
              defaultValue={Number(props.data.stb_price).toFixed()}
              onChange={(e) => setStbPrice(Number(e.target.value))}
            />
            <TextField
              id="currentStbPrice"
              label="Current STB price"
              type="number"
              defaultValue={Number(props.data.current_stb_price).toFixed()}
              onChange={(e) => setCurrentStbPrice(Number(e.target.value))}
            />
            <TextField
              id="notes"
              label="Notes"
              type="text"
              defaultValue={props.data.notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Button color="primary" onClick={() => props.editPayment(paymentDate, amountUsdt, amountStb, stbPrice, currentStbPrice, props.data.id, notes)}>
              edit payment
            </Button>
            {props.loading ? 
              <LinearProgress />
            :null}
            {props.error ?  ( 
              <Alert severity="error" >
                {props.error}
              </Alert>
            ): null}
            {props.success ? (
              <Alert severity="success" >
              {props.success}
            </Alert>
            ): null}
          </form>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display:'flex',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      margin:'auto',
      top: '50%',
    },
    modalStyle: {
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    formStyle: {
      display:'flex',
      flexDirection: 'column',
      width:400,
    }
  }),
);