import {Dispatch} from '@reduxjs/toolkit';
import {apiCall} from '../ApiCall';

/* export type OptionRates = {
  id: number;
  exchange: string;
  strike_price: number;
  bid_price: number;
  ask_price: number;
  type: string;
  expiration: string;
}; */

type OptionRatesResponse = {
  optionRates: [][];
};

type optionRatesAction = {
  readonly type: 'OPTION_RATES_ACTION';
  readonly payload: [][];
};
///optionArbitrage/option-rates
export const getOptionRates =
  (data: {baseCurr: string; addMonths: number}) =>
  async (dispatch: Dispatch<optionRatesAction>) => {
    apiCall
      .makeJsonApiCall<OptionRatesResponse>(
        'POST',
        '/optionArbitrage/option-rates',
        data,
      )
      .then((response) => {
        dispatch({
          type: 'OPTION_RATES_ACTION',
          payload: response.optionRates,
        });
      }).catch(console.error);
  };

export type Actions = optionRatesAction;
