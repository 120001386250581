let configLocal = require('./config.local.json');
let configDev = require('./config.dev.json');
let configProd = require('./config.prod.json');

export class Config {
  url!: string;
}

let apiConfig: Config;

console.log(process.env.REACT_APP_STAGE);
if (process.env.REACT_APP_STAGE === 'development') {
  apiConfig = configDev.api;
} else if (process.env.REACT_APP_STAGE === 'local') {
  apiConfig = configLocal.api;
} else {
  apiConfig = configProd.api;
}

export { apiConfig };
